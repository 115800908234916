import React from "react";
import { Layout, Menu } from "antd";
import {
  ShopOutlined,
  TeamOutlined,
  CodeSandboxOutlined,
  ShoppingCartOutlined,
  AppstoreFilled,
  AlertFilled,
  SettingFilled,
  DollarCircleFilled,
  PropertySafetyOutlined,
  RocketOutlined,
  PieChartOutlined,
  CommentOutlined,
  ExceptionOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import TajiraLogo from "../../../images/tajira-logo.png";
import Favicon from "../../../images/favicon.png";

const { Sider } = Layout;
const { SubMenu } = Menu;

const SiderBar = ({ toggle, collapsed }) => {
  const navigate = useNavigate();

  return (
    <Sider collapsible collapsed={collapsed} onCollapse={toggle}>
      <div className="tajira-logo">
        <img src={collapsed ? Favicon : TajiraLogo} alt="" />
      </div>
      <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
        <Menu.Item
          icon={<AppstoreFilled />}
          onClick={() => navigate("/dashboard")}
          key="1"
        >
          Dashboard
        </Menu.Item>
        <Menu.Item
          icon={<AlertFilled />}
          onClick={() => navigate("/notification/send-notification")}
          key="3"
        >
          Send Notification
        </Menu.Item>
        <SubMenu key="3" icon={<SettingFilled />} title="Settings">
          <Menu.Item
            key="3a"
            onClick={() => navigate("/settings/global-settings")}
          >
            Global Settings
          </Menu.Item>
          <Menu.Item
            key="3b"
            onClick={() => navigate("/settings/app-settings")}
          >
            App Settings
          </Menu.Item>
          <Menu.Item key="3c" onClick={() => navigate("/settings/list-banner")}>
            Banner Settings
          </Menu.Item>
          <Menu.Item key="3d" onClick={() => navigate("/settings/list-staff")}>
            staffs Settings
          </Menu.Item>
        </SubMenu>
        <Menu.Item
          key="4"
          icon={<TeamOutlined />}
          onClick={() => navigate("/customer/list-customer")}
        >
          Customers
        </Menu.Item>
        <SubMenu key="5" icon={<ShoppingCartOutlined />} title="Inventory">
          <Menu.Item
            key="5a"
            onClick={() => navigate("/inventory/list-attribute")}
          >
            Attributes
          </Menu.Item>
          <Menu.Item key="5b" onClick={() => navigate("/inventory/list-brand")}>
            Brands
          </Menu.Item>
          <Menu.Item
            key="5c"
            onClick={() => navigate("/inventory/list-category")}
          >
            Categories
          </Menu.Item>
          <Menu.Item
            key="5d"
            onClick={() => navigate("/inventory/list-product")}
          >
            Products
          </Menu.Item>
          <Menu.Item
            key="5e"
            onClick={() => navigate("/inventory/list-coupon")}
          >
            Coupons
          </Menu.Item>
          <Menu.Item key="5f" onClick={() => navigate("/inventory/list-deal")}>
            Deals
          </Menu.Item>
          <Menu.Item
            key="5g"
            onClick={() => navigate("/inventory/list-reviews")}
          >
            Reviews
          </Menu.Item>
        </SubMenu>
        <SubMenu key="6" icon={<CodeSandboxOutlined />} title="Orders">
          <Menu.Item key="6a" onClick={() => navigate("/orders/list-order")}>
            Order List
          </Menu.Item>
          <Menu.Item key="6c" onClick={() => navigate("/orders/list-cancel")}>
            Cancel Request
          </Menu.Item>
          <Menu.Item key="6d" onClick={() => navigate("/orders/list-return")}>
            Return Request
          </Menu.Item>
          <Menu.Item key="6b" onClick={() => navigate("/orders/list-reason")}>
            Reason List
          </Menu.Item>
        </SubMenu>
        <Menu.Item
          key="7"
          icon={<ShopOutlined />}
          onClick={() => navigate("/store/list-store")}
        >
          Stores
        </Menu.Item>
        <SubMenu key="8" icon={<DollarCircleFilled />} title="Payment">
          <Menu.Item
            key="8a"
            onClick={() => navigate("/payments/list-transaction")}
          >
            Transaction List
          </Menu.Item>
          <Menu.Item
            key="8b"
            onClick={() => navigate("/payments/list-commission")}
          >
            Commission List
          </Menu.Item>
          <Menu.Item key="8c" onClick={() => navigate("/payments/list-payout")}>
            Payout List
          </Menu.Item>
          <Menu.Item
            key="8d"
            onClick={() => navigate("/payments/list-banks-info")}
          >
            Bank Informations
          </Menu.Item>
          <Menu.Item
            key="8e"
            onClick={() => navigate("/payments/list-wallet-recharge")}
          >
            Wallet Recharge
          </Menu.Item>
        </SubMenu>
        <SubMenu key="9" icon={<PropertySafetyOutlined />} title="Membership">
          <Menu.Item
            key="9a"
            onClick={() => navigate("/membership/list-membership")}
          >
            Membership List
          </Menu.Item>
          <Menu.Item
            key="9b"
            onClick={() => navigate("/membership/list-package")}
          >
            Package List
          </Menu.Item>
        </SubMenu>
        <SubMenu key="10" icon={<RocketOutlined />} title="Delivery">
          {/* <Menu.Item
            key="10a"
            onClick={() => navigate("/delivery/list-shipment")}
          >
            Shipment List
          </Menu.Item> */}
          <Menu.Item
            key="10a"
            onClick={() => navigate("/delivery/list-delivery-charge")}
          >
            Delivery Charges
          </Menu.Item>
          <Menu.Item
            key="10b"
            onClick={() => navigate("/delivery/delivery-partner-settings")}
          >
            Delivery Partners
          </Menu.Item>
        </SubMenu>
        <Menu.Item
          key="11"
          icon={<PieChartOutlined />}
          onClick={() => navigate("/reports")}
        >
          Reports
        </Menu.Item>
        <SubMenu key="12" icon={<QuestionCircleOutlined />} title="FAQs">
          <Menu.Item key="12a" onClick={() => navigate("/faq/list-faq")}>
            FAQ List
          </Menu.Item>
          <Menu.Item key="12b" onClick={() => navigate("/faq/add-faq")}>
            Add FAQ
          </Menu.Item>
        </SubMenu>
        <SubMenu key="13" icon={<ExceptionOutlined />} title="Term & Policies">
          <Menu.Item
            key="13a"
            onClick={() => navigate("/terms-and-policies/privacy-policy")}
          >
            Privacy Policy
          </Menu.Item>
          <Menu.Item
            key="13b"
            onClick={() => navigate("/terms-and-policies/refund-policy")}
          >
            Refund Policy
          </Menu.Item>
          <Menu.Item
            key="13c"
            onClick={() => navigate("/terms-and-policies/return-policy")}
          >
            Return Policy
          </Menu.Item>
          <Menu.Item
            key="13d"
            onClick={() => navigate("/terms-and-policies/order-cancel-policy")}
          >
            Order Cancel Policy
          </Menu.Item>
          <Menu.Item
            key="13e"
            onClick={() => navigate("/terms-and-policies/terms")}
          >
            Terms
          </Menu.Item>
          <Menu.Item
            key="13f"
            onClick={() => navigate("/terms-and-policies/about-us")}
          >
            About Us
          </Menu.Item>
        </SubMenu>
        <Menu.Item
          key="14"
          icon={<CommentOutlined />}
          onClick={() => navigate("/messages")}
        >
          Messages
        </Menu.Item>
        <div className="copyright-text">Copyright © 2022 Tajira</div>
      </Menu>
    </Sider>
  );
};

export default SiderBar;
