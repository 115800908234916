/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import AdminLayout from "./layout";
import SignIn from "./auth/sign-in";
import ResetPassword from "./auth/reset-password";

import { setUserData } from "../utils/redux/actions";

import "./App.scss";

const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userData } = useSelector((state) => {
    return state?.app;
  });

  const savedUserData = JSON.parse(
    localStorage.getItem("tajira-admin-panel-user-data")
  );

  useEffect(() => {
    dispatch(setUserData(savedUserData));
  }, []);

  const isLoggedIn = !!savedUserData?.token || !!userData?.token;

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    } else {
      navigate("/dashboard");
    }
  }, [isLoggedIn]);

  return (
    <div className="bugify-main-container">
      {isLoggedIn ? (
        <AdminLayout />
      ) : (
        <Routes>
          <Route path="/login" element={<SignIn />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Routes>
      )}
    </div>
  );
};

export default App;
